import { updateEntityService } from "../../service/UpdateEntityService";
import {
  currentEntityAction,
  updateCurrentListItemDispatcher,
  Operation,
  EntityType,
} from "./CurrentActions";
import {
  showMessageBarAction,
  removeToasterMessageAction,
  showErrorMessageBarAction,
} from "./UIActions";
import { formatSecondsToTime } from "../../util/Utils";

export const updateCurrentEntityDispatcher = (
  entityTypeName,
  entityId,
  formObject,
  action
) => {
  return async (dispatch, getState) => {
    const entity = await updateEntityService.updateEntity(
      dispatch,
      getState,
      entityTypeName,
      entityId,
      formObject,
      action
    );
    const currentIdState = getState().current.id;
    const entityIdName = entityTypeName + "Id";
    // Only update currentEntity if entityId is still the same as the id:
    if (
      typeof currentIdState[entityIdName] !== "undefined" &&
      currentIdState[entityIdName] === entityId
    ) {
      dispatch(currentEntityAction(entityTypeName, entity));
    }
    dispatch(
      updateCurrentListItemDispatcher(entityTypeName, entity, Operation.Update)
    );
    dispatch(
      showMessageBarAction(
        true,
        `${entityTypeName.replace(/([A-Z])/g, " $1").trim()} updated`
      )
    );
    return entity;
  };
};

export const updateAccountTypeAction = (accountId, accountType) => {
  return async (dispatch, getState) => {
    await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Account,
      accountId,
      { accountType },
      "updateAccountType"
    );
    dispatch(showMessageBarAction(true, "Account upgraded!"));
  };
};

export const updateUserShowIntroStepsAction = (userlId) => {
  return async (dispatch, getState) => {
    return dispatch(
      updateCurrentEntityDispatcher(
        EntityType.User,
        userlId,
        { showIntroSteps: false },
        null
      )
    );
  };
};

export const updatePasswordAction = (currentPassword, newPassword) => {
  const formObject = { currentPassword, newPassword };
  return async (dispatch, getState) => {
    await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.User,
      null,
      formObject,
      "updatePassword"
    );
    dispatch(showMessageBarAction(true, "Password updated"));
  };
};

export const updatePosterDispatcher = (contentId, playtime, callback) => {
  const formObject = { playtime };
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.Content,
        contentId,
        formObject,
        "updatePosterTime"
      )
      .then((user) => {
        dispatch(
          showMessageBarAction(
            true,
            `Setting thumbnail to ${formatSecondsToTime(playtime)}`
          )
        );
        callback && callback(user);
      })
      .catch((e) => {});
  };
};

export const trimContentAction = (contentId, startTime, endTime) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.Content,
      contentId,
      { startTime: startTime.toFixed(3), endTime: endTime.toFixed(3) },
      "trim"
    );
    if (content.contentStatus !== "DELETED") {
      await dispatch(currentEntityAction(EntityType.Content, content));
      await dispatch(
        updateCurrentListItemDispatcher(
          EntityType.Content,
          content,
          Operation.Update
        )
      );
    }
    dispatch(showMessageBarAction(true, `${content.name} trimmed`));
    return content;
  };
};

export const updateContentShareDispatcher = (contentShareId, formObject) => {
  return async (dispatch, getState) => {
    const contentShare = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.ContentShare,
      contentShareId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.ContentShare, contentShare));
    dispatch(showMessageBarAction(true, "Share updated"));
  };
};

export const updateChannelShareDispatcher = (channelShareId, formObject) => {
  return async (dispatch, getState) => {
    const channelShare = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.ChannelShare,
      channelShareId,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.ChannelShare, channelShare));
    dispatch(showMessageBarAction(true, "Share updated"));
  };
};

/** For account access requests accepted via email link: */
export const acceptAccessRequestDispatcher = (accessRequestCode) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.AccessRequest,
        accessRequestCode,
        {},
        "accept"
      )
      .then((accessRequest) => {
        dispatch(currentEntityAction(EntityType.AccessRequest, accessRequest));
      })
      .catch((e) => {});
  };
};

/** For content/channel share access requests accepted via email link: */
export const acceptShareRequestDispatcher = (entityType, requestCode) => {
  return async (dispatch, getState) => {
    const shareRequest = await updateEntityService.updateEntity(
      dispatch,
      getState,
      entityType + "Request",
      requestCode,
      {},
      "accept"
    );
    dispatch(currentEntityAction(EntityType.ShareRequest, shareRequest));
  };
};

/** For channel share access requests accepted via admin form: */
export const acceptRequestedChannelShareDispatcher = (channelShareId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.ChannelShare,
        channelShareId,
        {},
        "accept"
      )
      .then((channelShare) => {
        dispatch(currentEntityAction(EntityType.ChannelShare, channelShare));
      })
      .catch((e) => {});
  };
};

/** For content share access requests accepted via admin form: */
export const acceptRequestedContentShareDispatcher = (contentShareId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.ContentShare,
        contentShareId,
        {},
        "accept"
      )
      .then((contentShare) => {
        dispatch(currentEntityAction(EntityType.ContentShare, contentShare));
      })
      .catch((e) => {});
  };
};

export const markChatMessagesFromUserReadDispatcher = (fromUserId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        null,
        null,
        { fromUserId },
        "markChatMessagesFromUserRead"
      )
      .then((chatMessageList) => {
        for (var i = 0; i < chatMessageList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.ChatMessage,
              chatMessageList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const markParentNotificationMessagesReadDispatcher = (
  notificationMessageId
) => {
  return (dispatch, getState) => {
    dispatch(removeToasterMessageAction(notificationMessageId));
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.NotificationMessage,
        notificationMessageId,
        null,
        "markParentNotificationsRead"
      )
      .then((notificationMessageList) => {
        for (var i = 0; i < notificationMessageList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.NotificationMessage,
              notificationMessageList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const markAllNotificationsReadDispatcher = () => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        null,
        null,
        null,
        "markAllNotificationsRead"
      )
      .then((notificationMessageList) => {
        for (var i = 0; i < notificationMessageList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.NotificationMessage,
              notificationMessageList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const importRecordingsDispatcher = ({
  entityType,
  recordingIdList,
  channelId,
  channelName,
  isPrivate,
}) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        entityType,
        null,
        { channelId, channelName, recordingIdList, isPrivate },
        "importList"
      )
      .then((recordingList) => {
        for (var i = 0; i < recordingList.length; i++) {
          dispatch(
            updateCurrentListItemDispatcher(
              entityType,
              recordingList[i],
              Operation.Update
            )
          );
        }
      })
      .catch((e) => {});
  };
};

export const updateSegmentDispatcher = (
  contentId,
  text,
  startTime,
  endTime
) => {
  return async (dispatch, getState) => {
    const content = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.TranscriptContentMeta,
      contentId,
      { text, startTime, endTime },
      "updateSegment"
    );
    dispatch(currentEntityAction(EntityType.Content, content));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Content,
        content,
        Operation.Update
      )
    );
    dispatch(showMessageBarAction(true, "Transcript updated"));
    return content;
  };
};
