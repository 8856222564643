import React, { useEffect, useState } from "react";
import Head from "next/head";
import {
  loadTestimonials,
  loadVideoPopup,
  useAnalytics,
  useScript,
} from "../hooks";
import appicon from "./appicon.json";
import Script from "next/script";
import NavBar from "../nav/NavBar";

const SiteHead = ({
  showNavBar,
  enableFloatingNavBar,
  disableFixed,
  isJQueryLoadedCallback = () => {},
}) => {
  useScript({
    type: "application/ld+json",
    textContent: appicon,
  });

  useAnalytics();
  const [is51DegLoaded, setIs51DegLoaded] = useState(false);
  const [isJQueryLoaded, setIsJQueryLoaded] = useState(false);
  const [isMobileMenuLoaded, setIsMobileMenuLoaded] = useState(false);

  useEffect(() => {
    if (isJQueryLoaded && window.$) {
      isJQueryLoadedCallback();
    }
  }, [isJQueryLoaded]);

  useEffect(() => {
    if (isMobileMenuLoaded) window.loadMobileMenu();
  }, [isMobileMenuLoaded]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="content-type" content="text/html; charSet=utf-8" />
        <meta name="author" content="PixelMixer" />
        <title>PixelMixer - Knowledge Sharing and Collaboration</title>
        <meta
          name="description"
          content="Elevate your team's ability to find and share critical information. Accelerate on-boarding, training, and knowledge sharing with intelligent, self-service video."
        />
        <meta
          property="og:title"
          content="PixelMixer - Knowledge Sharing and Collaboration"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="Elevate your team's ability to find and share critical information. Accelerate on-boarding, training, and knowledge sharing with intelligent, self-service video."
        />
        <meta
          property="og:image"
          content="https://www.pixelmixer.com/static/images/screens/trending-homepage.jpg"
        />
        <meta property="og:url" content="https://www.pixelmixer.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link
          rel="shortcut icon"
          href="/static/img/play-blade-only-color.png"
          type="image/x-icon"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/favicon/safari-pinned-tab.svg"
          color="#673ab7"
        />
        <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          httpEquiv="Delegate-CH"
          content="sec-ch-ua-full-version-list https://cloud.51degrees.com; sec-ch-ua-model https://cloud.51degrees.com; sec-ch-ua-platform https://cloud.51degrees.com; sec-ch-ua-platform-version https://cloud.51degrees.com"
        />
      </Head>
      <Script
        onLoad={() => setIs51DegLoaded(true)}
        src="https://cloud.51degrees.com/api/v4/AQTPkKrCdAyFj3o23Eg.js"
      ></Script>
      {is51DegLoaded && <Script src="/static/js/deviceapi.js"></Script>}
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=AW-731004586"
      ></Script>
      <Script
        src="/static/website-assets/js/plugins/jquery-3-6-0.min.js"
        onReady={() => setIsJQueryLoaded(true)}
      ></Script>
      <Script src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></Script>

      {isJQueryLoaded && (
        <>
          <Script src="/static/website-assets/js/plugins/bootstrap.min.js"></Script>
          <Script
            src="/static/website-assets/js/plugins/mobilemenu.js"
            onReady={() => setIsMobileMenuLoaded(true)}
          ></Script>
        </>
      )}

      {/* <!--===== PRELOADER STARTS =======--> */}
      {/* <div id="preloader">
        <div className="preloader">
          <span></span>
          <span></span>
        </div>
      </div> */}
      {/* <!--===== PRELOADER ENDS =======--> */}

      {/* <!--===== PAGE PROGRESS START=======--> */}
      <div className="paginacontainer">
        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      </div>
      {/* <!--===== PAGE PROGRESS END=======--> */}
      {showNavBar && (
        <NavBar
          isJQueryLoaded={isJQueryLoaded}
          enableFloatingNavBar={enableFloatingNavBar}
          disableFixed={disableFixed}
        />
      )}
    </>
  );
};

export default SiteHead;
