import { fetchEntityService } from "../../service/FetchEntityService";
import { fetchListService } from "../../service/FetchListService";
import {
  searchStringAction,
  showModalAction,
  ModalName,
  showMessageBarAction,
  showErrorMessageBarAction,
} from "./UIActions";
import {
  sortList,
  entityListKeyMapping,
} from "../reducer/CurrentListActionReducer";
import { markChatMessagesFromUserReadDispatcher } from "./UpdateEntityActions";
import { createEntityService } from "../../service/CreateEntityService";
import { browserHistory } from "../../util/Utils";
import request from "superagent";
import { logError } from "../../service/ServiceUtil";

export const Operation = {
  Create: "CREATE",
  Update: "UPDATE",
  Delete: "DELETE",
};

export const EntityType = {
  AccessRequest: "accessRequest",
  Account: "account",
  AccountInvite: "accountInvite",
  AccountUser: "accountUser",
  Caption: "Caption",
  Channel: "channel",
  ChannelShare: "channelShare",
  ChannelShareUser: "channelShareUser",
  ChannelSubscription: "channelSubscription",
  ChatMessage: "chatMessage",
  ChatUser: "chatUser",
  ChatConversation: "chatConversation",
  Content: "content",
  ContentEvent: "contentEvent",
  ContentShare: "contentShare",
  ContentShareUser: "contentShareUser",
  Comment: "comment",
  Download: "download",
  EntityContentMeta: "entityContentMeta",
  EntitySocketSession: "entitySocketSession",
  FileUpload: "fileUpload",
  GoogleDriveFile: "googleDriveFile",
  Keyword: "keyword",
  IdentityProvider: "identityProvider",
  NotificationMessage: "notificationMessage",
  Organization: "organization",
  PasswordReset: "passwordReset",
  Playlist: "playlist",
  PlaylistContent: "playlistContent",
  RingCentralRecording: "ringCentralRecording",
  SegmentKeyword: "segmentKeyword",
  ShareRequest: "shareRequest",
  SlackAccountUser: "slackAccountUser",
  SlackChannel: "slackChannel",
  SlackKeywordAlert: "slackKeywordAlert",
  Subscription: "subscription",
  TranscriptContentMeta: "transcriptContentMeta",
  Upload: "upload",
  User: "user",
  UserContentMeta: "userContentMeta",
  UserProfile: "userProfile",
  UserSession: "userSession",
  WebexRecording: "webexRecording",
  WeVideo: "weVideo",
  ZoomLink: "zoomLink",
  ZoomRecording: "zoomRecording",
};

export const currentIdAction = (entityTypeName, id) => {
  return {
    type: "CURRENT_ID_ACTION",
    entityTypeName,
    id,
  };
};

export const currentEntityAction = (entityTypeName, entity) => {
  return {
    type: "CURRENT_ENTITY_ACTION",
    entityTypeName,
    entity,
  };
};

export const clearCurrentStateAction = () => {
  return {
    type: "CLEAR_CURRENT_STATE_ACTION",
  };
};

export const setCurrentPlaytimeAction = (playtime) => {
  return {
    type: "CURRENT_PLAYTIME_ACTION",
    playtime,
  };
};

export const fetchCurrentListDispatcher = (
  listName,
  parentEntityTypeName,
  parentEntityId,
  queryObject = {}
) => {
  return async (dispatch, getState) => {
    const jsonList = await fetchListService.fetchList(
      dispatch,
      getState,
      listName,
      parentEntityTypeName,
      parentEntityId,
      queryObject
    );
    const currentIdState = getState().current.id;
    // Only update currentList of currentEntityId is still the same as the parentId
    // (or no parentEntityTypeName was provided;):
    if (
      !parentEntityTypeName ||
      (currentIdState[parentEntityTypeName + "Id"] &&
        currentIdState[parentEntityTypeName + "Id"] === parentEntityId)
    ) {
      dispatch(currentListAction(listName, jsonList));
    }
    return sortList(jsonList, listName);
  };
};

export const mergeCurrentListDispatcher = (
  listName,
  listItemEntityTypeName,
  parentEntityTypeName,
  parentEntityId,
  queryObject = {},
  callback
) => {
  return (dispatch, getState) => {
    fetchListService
      .fetchList(
        dispatch,
        getState,
        listName,
        parentEntityTypeName,
        parentEntityId,
        queryObject
      )
      .then((jsonListObject) => {
        let currentIdState = getState().current.id;
        // Only update currentList of currentEntityId is still the same as the parentId
        // (or no parentEntityTypeName was provided;):
        if (
          !parentEntityTypeName ||
          (currentIdState[parentEntityTypeName + "Id"] &&
            currentIdState[parentEntityTypeName + "Id"] === parentEntityId)
        ) {
          jsonListObject = sortList(jsonListObject, listName);
          dispatch(
            mergeCurrentListAction(listItemEntityTypeName, jsonListObject)
          );
        }
        if (callback) callback(jsonListObject);
      })
      .catch((error) => {});
  };
};

export const fetchCurrentEntityAction = (
  entityTypeName,
  entityId
) => {
  return async (dispatch, getState) => {
    const entity = await fetchEntityService.fetchEntity(
      dispatch,
      getState,
      entityTypeName,
      entityId
    );
    dispatch(currentEntityAction(entityTypeName, entity));
    return entity;
  };
};

export const currentIdDispatcher = (entityTypeName, id, queryObj, action) => {
  return async (dispatch, getState) => {
    dispatch(currentIdAction(entityTypeName, id));
    if (id) {
      const entity = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        entityTypeName,
        id,
        action,
        queryObj
      );
      const state = getState();
      if (state.current.id[`${entityTypeName}Id`] === id) {
        dispatch(currentEntityAction(entityTypeName, entity));
      }
      return entity;
    } else {
      dispatch(currentEntityAction(entityTypeName, null));
    }
  };
};

export const currentListAction = (listName, currentList) => {
  return {
    type: "CURRENT_LIST_ACTION",
    listName,
    currentList,
  };
};

export const mergeCurrentListAction = (entityTypeName, updatedItemsList) => {
  return {
    type: "MERGE_CURRENT_LIST_ACTION",
    entityTypeName,
    updatedItemsList,
  };
};

export const updateCurrentListItemDispatcher = (
  entityTypeName,
  item,
  operation
) => {
  return (dispatch, getState) => {
    if (typeof entityListKeyMapping[entityTypeName] !== "undefined") {
      dispatch({
        type: "REMOVE_CURRENT_LIST_ITEM_PARENT_CHANGE_ACTION",
        entityTypeName,
        item,
      });
      const entityLists = entityListKeyMapping[entityTypeName];
      const state = getState();
      for (let listName in entityLists) {
        if (entityLists.hasOwnProperty(listName)) {
          const parentKeyName = entityLists[listName];
          const parentKeyValue = item[parentKeyName];
          // Don't CREATE items for lists where parentKeyName is either
          // a. unspecified
          // b. specified and the parentKeyValue doesn't match with current.id
          if (
            operation !== Operation.Create ||
            (parentKeyName &&
              state.current.id[parentKeyName] === parentKeyValue)
          ) {
            dispatch({
              type: "UPDATE_CURRENT_LIST_ITEM_ACTION",
              entityTypeName,
              item,
              operation,
              listName,
            });
          }
        }
      }
    }
  };
};

export const updateCurrentEntityChildItemAction = (entityTypeName, item) => {
  return {
    type: "UPDATE_CURRENT_ENTITY_CHILD_ITEM_ACTION",
    entityTypeName,
    item,
  };
};

export const contentSearchDispatcher = (
  searchString,
  listName,
  parentEntityTypeName,
  parentEntityId
) => {
  return async (dispatch, getState) => {
    try {
      if (getState().login.isLoggedIn) {
        dispatch(searchStringAction(searchString, listName));
        return await dispatch(
          fetchCurrentListDispatcher(
            listName,
            parentEntityTypeName,
            parentEntityId,
            {
              searchString,
            }
          )
        );
      } else {
        dispatch(showModalAction(ModalName.CreateLoginModal, true, "register"));
        dispatch(showMessageBarAction(true, "Sign-in for additional features"));
      }
      throw new Error("Not signed in");
    } catch (e) {
      logError("contentSearchDispatcher", {}, e);
      throw e;
    }
  };
};

export const fetchChatConversationPageDispatcher = (
  userId,
  chatConversationId,
  page
) => {
  return (dispatch, getState) => {
    dispatch(
      mergeCurrentListDispatcher(
        "chatMessages",
        EntityType.ChatMessage,
        EntityType.ChatConversation,
        chatConversationId,
        { page },
        (chatMessageList) => {
          for (var i = 0; i < chatMessageList.length; i++) {
            let chatMessage = chatMessageList[i];
            if (chatMessage.fromUser.id !== userId && !chatMessage.isRead) {
              dispatch(
                markChatMessagesFromUserReadDispatcher(chatMessage.fromUser.id)
              );
              break;
            }
          }
        }
      )
    );
  };
};

export const currentContentIdDispatcher = (id, queryObj) => {
  return async (dispatch, getState) => {
    const content = await dispatch(
      currentIdDispatcher(EntityType.Content, id, queryObj)
    );
    await dispatch(
      currentIdDispatcher(EntityType.Account, content.accountId, queryObj)
    );
    return content;
  };
};

export const currentChannelIdDispatcher = (id, queryObj) => {
  return async (dispatch, getState) => {
    const channel = await dispatch(
      currentIdDispatcher(EntityType.Channel, id, queryObj)
    );
    await dispatch(
      currentIdDispatcher(EntityType.Account, channel.accountId, queryObj)
    );
    return channel;
  };
};

export const currentAccountInviteDispatcher = (queryObj) => {
  return async (dispatch, getState) => {
    try {
      const accountInvite = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.AccountInvite,
        null,
        null,
        queryObj
      );
      dispatch(currentEntityAction(EntityType.AccountInvite, accountInvite));
      dispatch(currentIdAction(EntityType.Account, accountInvite.account.id));
      dispatch(currentEntityAction(EntityType.Account, accountInvite.account));
    } catch (error) {
      if (error.errorCode === "EmailVerificationRequired")
        // Magical generateEmailVerificationRequiredResponse from server:
        dispatch(currentEntityAction(EntityType.User, error));
      throw error;
    }
  };
};

export const currentPasswordResetDispatcher = (keyCode) => {
  return (dispatch, getState) => {
    fetchEntityService
      .fetchEntity(dispatch, getState, EntityType.PasswordReset, null, null, {
        keyCode,
      })
      .then((passwordReset) => {
        dispatch(currentEntityAction(EntityType.PasswordReset, passwordReset));
      })
      .catch((e) => {
        browserHistory.push("/forgotPassword");
      });
  };
};

export const currentUserFromEmailLinkDispatcher = (emailVerifyCode) => {
  return (dispatch, getState) => {
    let formObject = {
      emailVerifyCode,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        "user/verifyEmailLink",
        null,
        formObject
      )
      .then((user) => {
        dispatch(currentEntityAction(EntityType.User, user));
        dispatch(showErrorMessageBarAction(false));
      })
      .catch((e) => {});
  };
};

export const currentUserFromEmailPinDispatcher = (
  emailAddress,
  emailVerifyPin
) => {
  return async (dispatch, getState) => {
    const formObject = {
      emailAddress,
      emailVerifyPin,
    };
    try {
      const user = await createEntityService.createEntity(
        dispatch,
        null,
        "user/verifyEmailPin",
        null,
        formObject
      );
      user.emailVerifyPin = emailVerifyPin;
      dispatch(currentEntityAction(EntityType.User, user));
      dispatch(showErrorMessageBarAction(false));
      return user;
    } catch (e) {
      throw e;
    }
  };
};

export const currentContentMetaDispatcher = (content, contentMetaType) => {
  return (dispatch, getState) => {
    dispatch(currentIdAction(contentMetaType, content.id));
    const url =
      contentMetaType === EntityType.EntityContentMeta
        ? content.entityContentMetaUrl
        : content.transcriptContentMetaUrl;
    url &&
      request
        .get(url)
        .set("Accept", "application/json")
        .end((error, response) => {
          const contentMetaData = response?.ok && response?.body;
          if (contentMetaData) {
            dispatch(currentEntityAction(contentMetaType, contentMetaData));
          }
        });
  };
};

export const fetchKeywordTagCloudDispatcher = () => {
  return async (dispatch, getState) => {
    const keywordTagCloud = await fetchListService.fetchList(
      dispatch,
      getState,
      "tagCloud",
      EntityType.Keyword
    );
    dispatch(currentListAction("keywordTagCloud", keywordTagCloud));
  };
};

export const currentDownloadDispatcher = (contentId) => {
  return async (dispatch, getState) => {
    if (contentId) {
      const download = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Content,
        contentId,
        EntityType.Download
      );
      dispatch(currentIdAction(EntityType.Download, download.id));
      dispatch(currentEntityAction(EntityType.Download, download));
      return download;
    } else {
      dispatch(currentEntityAction(EntityType.Download, null));
    }
  };
};
