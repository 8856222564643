import { createEntityService } from "../../service/CreateEntityService";
import {
  isAccountUpgradeRequired,
  isFreeAccount,
  refreshUserSessionDispatcher,
} from "./LoginActions";
import {
  showMessageBarAction,
  showErrorMessageBarAction,
  showModalAction,
  ModalName,
} from "./UIActions";
import { refreshCurrentUserSessionStateDispatcher } from "./LoginActions";
import {
  currentEntityAction,
  updateCurrentListItemDispatcher,
  Operation,
  EntityType,
} from "./CurrentActions";
import queue from "queue";
import {
  browserHistory,
  trackIntercomEvent,
  isTestEnv,
  isTestAgent,
} from "../../util/Utils";
import { fetchEntityService } from "../../service/FetchEntityService";
import { updateEntityService } from "../../service/UpdateEntityService";
import request from "superagent";
import mixpanel from "mixpanel-browser";
import { Logger } from "aws-amplify";
import { logError } from "../../service/ServiceUtil";

export const ShareType = {
  ACCESS_REQUEST: "access request",
  APP: "app",
  EMAIL: "email",
  EMBED: "embedded player",
  PORTAL: "portal",
  LINK: "copied link",
  SHARE_REQUEST: "share request",
  SMS: "SMS",
  USER_EMAIL: "user email",
  USER_SMS: "user SMS",
};

export const RecordingImportStatus = {
  NONE: "",
  IMPORT: "In progress",
  ERROR: "Error",
  SUCCESS: "On-line",
};

export const GettingStartedStep = {
  Record: "Record",
  Share: "Share",
  Notifications: "Notifications",
  Channels: "Channels",
  Done: "Done",
};

export const createAccountDispatcher = (formObj) => {
  return async (dispatch, getState) => {
    try {
      const account = await createEntityService.createEntity(
        dispatch,
        null,
        "account",
        {},
        formObj
      );
      dispatch(currentEntityAction(EntityType.Account, account));
    } catch (error) {
      //This logic is to allow the user to stay in the create account flow,
      //incase they are trying again:
      if (error.errorCode === "AlreadyExists") {
        dispatch(currentEntityAction(EntityType.Account, error));
        return error;
      } else if (error.errorCode === "NotSignedIn") {
        browserHistory.push("/login");
      }
      throw error;
    }
  };
};

export const createCommentDispatcher = (
  contentId,
  message,
  playtime,
  parentCommentId,
  shareCodes
) => {
  return (dispatch, getState) => {
    const queryObject = {
      contentId,
      playtime,
      parentCommentId,
      ...shareCodes,
    };
    const formObject = {
      message,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.Comment,
        queryObject,
        formObject
      )
      .then((comment) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.Comment,
            comment,
            Operation.Create
          )
        );
        dispatch(showMessageBarAction(true, "comment posted!"));
        trackEvent("videoComment", { contentId: comment.contentId });
        trackIntercomEvent("post comment", { contentId, message });
      })
      .catch((e) => {});
  };
};

export const createUserSessionDispatcher = (shareCodes, referralSource) => {
  return async (dispatch, getState) => {
    const userSession = await createEntityService.createEntity(
      dispatch,
      null,
      EntityType.UserSession,
      {},
      {
        ...shareCodes,
        referralSource,
      }
    );
    if (userSession.isNewAccount) {
      trackEvent("createAccount");
      trackConversion();
    }
    dispatch(showErrorMessageBarAction(false));
    dispatch(refreshCurrentUserSessionStateDispatcher(userSession));
    return userSession;
  };
};

export const createUserDispatcher = (formObject, callback) => {
  return async (dispatch, getState) => {
    const user = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.User,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.User, user));
    dispatch(showModalAction(ModalName.VerifyEmailPinModal, true));
    trackEvent("createAccountUser", { accountUserId: user.id });
  };
};

export const createChannelDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const channel = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.Channel,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    browserHistory.push(`/channel/${channel.id}`);
    trackIntercomEvent("create channel", formObject);
  };
};

export const createChatMessageDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(dispatch, getState, EntityType.ChatMessage, {}, formObject)
      .then((chatMessage) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.ChatMessage,
            chatMessage,
            Operation.Create
          )
        );
        trackEvent("chatMessage", { toUserId: chatMessage.toUserId });
        trackIntercomEvent("sent message", formObject);
      })
      .catch((e) => {});
  };
};

export const createChannelSubscriptionDispatcher = (channelId) => {
  return (dispatch, getState) => {
    const formObject = {
      channelId,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.ChannelSubscription,
        {},
        formObject
      )
      .then((channelSubscription) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(
          showMessageBarAction(
            true,
            `You are now subscribed to ${channelSubscription.channel.name}`
          )
        );
        dispatch(refreshUserSessionDispatcher(getState().login.sessionKey));
        trackEvent("channelSubscription", { channelId: channelId });
        trackIntercomEvent("subscribed to channel", formObject);
      })
      .catch((e) => {});
  };
};

export const verifyPhoneDispatcher = (userId, phoneVerifyPin) => {
  return (dispatch, getState) => {
    const formObject = {
      userId,
      phoneVerifyPin,
    };
    createEntityService
      .createEntity(dispatch, getState, "user/verifyPhone", {}, formObject)
      .then((user) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(currentEntityAction(EntityType.User, user));
      })
      .catch((e) => {});
  };
};

export const sendPhoneVerificationDispatcher = (
  userId,
  emailVerifyCode,
  phone
) => {
  return (dispatch, getState) => {
    let formObject = {
      userId,
      emailVerifyCode,
      phone,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        "user/sendPhoneVerificationPin",
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(currentEntityAction(EntityType.User, jsonObject));
      })
      .catch((e) => {});
  };
};

export const createAccountInviteDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const accountInvite = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.AccountInvite,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(true, `Invite sent to ${accountInvite.emailAddress}`)
    );
    trackEvent("inviteUser");
    trackIntercomEvent("sent invite", formObject);
  };
};

export const createAccessRequestDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.AccessRequest,
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(
          showMessageBarAction(
            true,
            `Sent! You will receive an email once accepted`
          )
        );
        trackIntercomEvent("requested access", formObject);
      })
      .catch((e) => {});
  };
};

export const sendAccountInviteRequestDispatcher = (inviteCode) => {
  return (dispatch, getState) => {
    let queryObject = {
      inviteCode,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.AccountInvite + "/sendAccountInviteRequest",
        queryObject,
        {}
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(
          showMessageBarAction(
            true,
            `Request sent to ${jsonObject.createdByAccountUser.user.emailAddress}`
          )
        );
        trackIntercomEvent("requested invite");
      })
      .catch((e) => {});
  };
};

export const createContentShareDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const contentShare = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ContentShare,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.ContentShare, contentShare));
    trackEvent("shareVideoLink", { contentId: contentShare.contentId });
    trackIntercomEvent("copied share link", formObject);
  };
};

export const createChannelShareDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const channelShare = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChannelShare,
      {},
      formObject
    );
    dispatch(currentEntityAction(EntityType.ChannelShare, channelShare));
    dispatch(showErrorMessageBarAction(false));
    trackEvent("shareChannelLink", { channelId: channelShare.channelId });
    trackIntercomEvent("copied channel share link", formObject);
  };
};

export const createChannelShareUserDispatcher = (formObject) => {
  const { channelId, sendEmail, emailAddress, phone } = formObject;
  return async (dispatch, getState) => {
    const channelShareUser = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChannelShareUser,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Link sent to ${sendEmail ? emailAddress : phone}`
      )
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ChannelShareUser,
        channelShareUser,
        Operation.Create
      )
    );
    trackEvent("shareChannel", { channelId: channelId });
    trackIntercomEvent("sent channel share link", formObject);
  };
};

export const createContentShareUserDispatcher = (formObject) => {
  const { contentId, sendEmail, emailAddress, phone } = formObject;
  return async (dispatch, getState) => {
    const contentShareUser = createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ContentShareUser,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Link sent to ${sendEmail ? emailAddress : phone}`
      )
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ContentShareUser,
        contentShareUser,
        Operation.Create
      )
    );
    trackEvent("shareVideo", { contentId: contentId });
    trackIntercomEvent("sent share link", formObject);
  };
};

export const sendContentShareRequestDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.ContentShare + "/sendShareRequest",
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Request sent`));
        trackIntercomEvent("requested share", formObject);
      })
      .catch((e) => {});
    dispatch(showModalAction(ModalName.RequestShareModal, false));
  };
};

export const sendChannelShareRequestDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.ChannelShare + "/sendShareRequest",
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Request sent`));
        trackIntercomEvent("requested channel share", formObject);
      })
      .catch((e) => {});
    dispatch(showModalAction(ModalName.RequestShareModal, false));
  };
};

export const resendAccountInviteDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.AccountInvite + "/resendAccountInvite",
        null,
        formObject
      )
      .then((accountInvite) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(currentEntityAction(EntityType.AccountInvite, accountInvite));
        dispatch(
          showMessageBarAction(
            true,
            `Invite sent to ${accountInvite.emailAddress}`
          )
        );
        trackIntercomEvent("resent invite", formObject);
      })
      .catch((error) => {
        if (error.errorCode === "Already Exists") {
          dispatch(currentEntityAction(EntityType.AccountInvite, error));
        } else {
          dispatch(currentEntityAction(EntityType.AccountInvite, {}));
        }
      });
  };
};

export const sendPasswordResetLinkDispatcher = (emailAddress) => {
  return async (dispatch, getState) => {
    let formObject = {
      emailAddress,
    };
    try {
      const passwordReset = await createEntityService.createEntity(
        dispatch,
        getState,
        "passwordReset/sendResetLink",
        {},
        formObject
      );
      dispatch(
        showErrorMessageBarAction(true, "Check email to reset password")
      );
      trackIntercomEvent("sent password reset", formObject);
      return passwordReset;
    } catch (e) {
      throw e;
    }
  };
};

const contentEventQueue = queue();
contentEventQueue.concurrency = 1;
contentEventQueue.autostart = true;
var lastContentEventTime = {
  BEGIN: 0,
  START: 0,
  PLAY: 0,
  REWIND: 0,
  FORWARD: 0,
  PAUSE: 0,
  STOP: 0,
  WATCH: 0,
  FINISH: 0,
  LIKED: 0,
};
export const createContentEventDispatcher = (
  contentId,
  contentEventType,
  playtime = 0,
  urlPath,
  shareCodes = {}
) => {
  const endpointUrl = "/api/gw/pixelmixer-content-event-queue-service";
  return (dispatch, getState) => {
    let timeNow = new Date();
    var soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 1);
    if (
      !lastContentEventTime[contentEventType] ||
      lastContentEventTime[contentEventType] < soonestTime
    ) {
      lastContentEventTime[contentEventType] = timeNow;
      const formObject = {
        contentId,
        contentEventType,
        playtime,
        createdDate: timeNow.getTime(),
        urlPath,
        xSessionKey: getState().login.sessionKey,
        ...shareCodes,
      };
      contentEventQueue.push((cb) => {
        request
          .post(endpointUrl)
          .set("Accept", "text/plain")
          .type("text/plain")
          .send(JSON.stringify(formObject))
          .end((error, response) => {
            if (error) logError("createContentEventDispatcher", cb, error);
            else if (!response.ok)
              logError("createContentEventDispatcher", formObject);
            cb();
          });
      });
    }
  };
};

var lastLike = new Date();
export const createLikeDispatcher = (shareCodes, contentId, playtime) => {
  return (dispatch, getState) => {
    let timeNow = new Date();
    var soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 1);
    if (lastLike < soonestTime) {
      updateEntityService
        .updateEntity(
          dispatch,
          getState,
          EntityType.UserContentMeta,
          contentId,
          { playtime },
          "like",
          shareCodes
        )
        .then((userContentMeta) => {
          dispatch(
            showMessageBarAction(true, `liked ${userContentMeta.content.name}`)
          );
          trackEvent("likeVideo", { contentId: contentId });
          trackIntercomEvent("liked", {
            name: userContentMeta.content.name,
            playtime,
          });
        })
        .catch((e) => {});
    } else dispatch(showErrorMessageBarAction(true, "liking too much!"));
    lastLike = new Date();
  };
};

export const watchingContentDispatcher = (shareCodes, contentId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.UserContentMeta,
        contentId,
        {},
        "watched",
        shareCodes
      )
      .then((userContentMeta) => {
        trackEvent("viewVideo", { contentId: contentId });
        trackIntercomEvent("watching", {
          name: userContentMeta.content.name,
          contentId,
        });
      })
      .catch((e) => {});
  };
};

let isMixPanelLoaded = false;
export const trackEvent = (eventName, attrObj = {}) => {
  const initMixPanel = () => {
    mixpanel.init(
      isTestEnv
        ? "0fa4a781eecb6616af8501f73222e834"
        : "de8ad8bc9626f4c5a04e797be743f248",
      {
        debug: isTestEnv,
        ignore_dnt: true,
        track_pageview: true,
        persistence: "localStorage",
      }
    );
    isMixPanelLoaded = true;
  };
  try {
    if (!isTestAgent()) {
      if (!isMixPanelLoaded) initMixPanel();
      mixpanel.track(eventName, attrObj);
    }
  } catch (e) {
    logError("trackEvent", { eventName, attrObj }, e);
  }
};

export const trackConversion = () => {
  try {
    if (!isTestAgent()) {
      if (window.gtag)
        window.gtag("event", "conversion", {
          send_to: "AW-731004586/f8NaCO2VvKUBEKr9yNwC",
        });
      //LinkedIn Conversion event:
      if (window.lintrk) window.lintrk("track", { conversion_id: 7114292 });
    }
  } catch (e) {
    logError("trackConversion", {}, e);
  }
};

export const validateEntityNameDispatcher = (entityType, entityId, name) => {
  return async (dispatch, getState) => {
    let queryObject = {
      name: name,
    };
    if (entityId) queryObject[entityType + "Id"] = entityId;
    const result = await fetchEntityService.fetchEntity(
      dispatch,
      getState,
      entityType,
      false,
      "validateName",
      queryObject
    );
    dispatch(showErrorMessageBarAction(!result.valid, result.userMessage));
    return result.valid;
  };
};

export const subscriptionCheckoutDispatcher = (term) => {
  return async (dispatch, getState) => {
    const accountId = getState().current.entity.account.id;
    trackEvent("showSubscriptionCheckoutForm", { accountId });
    try {
      const result = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Subscription,
        false,
        "checkoutUrl",
        { term }
      );
      if (window) window.location = result.url;
    } catch (e) {
      logError("subscriptionCheckoutDispatcher", {term}, e);
    }
  };
};

export const subscriptionPortalDispatcher = () => {
  return async (dispatch, getState) => {
    try {
      const result = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Subscription,
        false,
        "portalUrl"
      );
      if (window) window.location = result.url;
    } catch (e) {
      logError("subscriptionPortalDispatcher", {}, e);
    }
  };
};
