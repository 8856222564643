import React, { useEffect } from "react";
import Link from "next/link";
import { useRef } from "react";
import { useStickyHeader } from "../hooks";

const NavBar = ({ isJQueryLoaded, enableFloatingNavBar, disableFixed }) => {
  const stopPropagation = (e) => e.stopPropagation();
  const headerRef = useRef();
  useStickyHeader(isJQueryLoaded && headerRef.current, enableFloatingNavBar);

  const closeMobileMenu = () => window.closeMobileMenu();
  return (
    <>
      {/* <!--=====HEADER START=======--> */}
      <header>
        <div
          ref={headerRef}
          className="header-area homepage1 header header-sticky d-none d-xl-block"
          id="header"
          style={{ position: disableFixed ? "relative" : "fixed" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <nav id="navbar-example2" className="navbar">
                  <div className="header-elements">
                    <div className="site-logo">
                      <Link href="/">
                        <img
                          src="/static/website-assets/images/custom/logo/play-logo-white.svg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="main-menu">
                      <ul className="nav nav-pills">
                        <li>
                          <Link href="#">
                            Use Cases <i className="fa-solid fa-angle-down"></i>
                          </Link>
                          <ul className="dropdown-padding">
                            <li>
                              <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                                Software Development
                              </Link>
                            </li>
                            <li>
                              <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                                Creative/UX
                              </Link>
                            </li>
                            <li>
                              <Link href="single-index3.html">
                                High-tech Manufacturing
                              </Link>
                            </li>
                            <li>
                              <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                                Engineering
                              </Link>
                            </li>
                            <li>
                              <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                                Product Development
                              </Link>
                            </li>
                            <li>
                              <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                                Program Management
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link href="#">
                            Resources <i className="fa-solid fa-angle-down"></i>
                          </Link>
                          <ul className="dropdown-padding">
                            <li>
                              <Link href="https://blog.pixelmixer.com">
                                Blog
                              </Link>
                            </li>
                            <li>
                              <Link href="https://blog.pixelmixer.com">
                                Thought Leadership
                              </Link>
                            </li>
                            <li>
                              <Link href="https://help.pixelmixer.com">
                                Support
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li className="nav-item">
                          <Link className="nav-link" href="/pricing">
                            <span>Pricing</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="cta-btn d-none d-md-block">
                      <Link href="/login" className="header-btn">
                        Login<i className="fa-solid fa-arrow-right-to-bracket"></i>
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!--=====HEADER END =======--> */}

      {/* <!--===== MOBILE HEADER STARTS =======--> */}
      <div
        className="mobile-header mobile-header1 d-block d-xl-none"
        style={{ position: disableFixed ? "relative" : "fixed" }}
      >
        <div className="container-fluid">
          <div className="col-12">
            <div className="mobile-header-elements">
              <div className="mobile-logo">
                <Link href="/" onClick={closeMobileMenu}>
                  <img
                    src="/static/website-assets/images/custom/logo/play-logo-white.svg"
                    alt=""
                  />
                </Link>
              </div>
              <div className="mobile-nav-icon dots-menu">
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-sidebar mobile-sidebar1">
        <div className="mobile-sidebar-background  background-gradient">
          <div className="background-gradient-overlay"></div>
          <div className="mobile-sidebar-container">
            <div className="logosicon-area">
              <div className="logos">
                <img
                  src="/static/website-assets/images/custom/logo/play-logo-white.svg"
                  alt="Home"
                />
              </div>
              <div className="menu-close">
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="mobile-nav">
              <ul className="mobile-nav-list">
                <li>
                  <Link href="#">Use Cases</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                        Software Development
                      </Link>
                    </li>
                    <li>
                      <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                        Creative/UX
                      </Link>
                    </li>
                    <li>
                      <Link href="single-index3.html">
                        High-tech Manufacturing
                      </Link>
                    </li>
                    <li>
                      <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                        Engineering
                      </Link>
                    </li>
                    <li>
                      <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                        Product Development
                      </Link>
                    </li>
                    <li>
                      <Link href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                        Program Management
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link href="#">Resources</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link href="https://blog.pixelmixer.com">Blog</Link>
                    </li>
                    <li>
                      <Link href="https://blog.pixelmixer.com">
                        Thought Leadership
                      </Link>
                    </li>
                    <li>
                      <Link href="https://help.pixelmixer.com">Support</Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    href="/pricing"
                    onClick={closeMobileMenu}
                  >
                    <span>Pricing</span>
                  </Link>
                </li>
              </ul>
              <div className="allmobilesection">
                <Link
                  href="/login"
                  className="mobile-login"
                  onClick={closeMobileMenu}
                >
                  Login<i className="fa-solid fa-arrow-right-to-bracket"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--===== MOBILE HEADER ENDS =======--> */}
    </>
  );
};

export default NavBar;
