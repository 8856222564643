import {
  currentEntityAction,
  Operation,
  EntityType,
  currentListAction,
  currentIdDispatcher,
  currentIdAction,
} from "./CurrentActions";
import {
  generateChatConversationId,
  toInt,
  browserHistory,
  isTestEnv,
} from "../../util/Utils";
import { markParentNotificationMessagesReadDispatcher } from "./UpdateEntityActions";
import { isFreeAccount } from "./LoginActions";

export const ModalName = {};

export const DialogName = {
  QuickDeleteDialog: "QuickDeleteDialog",
  QuickEmbedDialog: "QuickEmbedDialog",
  QuickShareDialog: "QuickShareDialog",
};

export const UIComponent = {};

export const RightSidebarComponent = {
  ChatSidebar: "ChatSidebar",
  UserSearchSidebar: "UserSearchSidebar",
};

export const ScriptName = {
  videojs: "videojs",
  nuevo: "nuevo",
  upnext: "upnext",
  offset: "offset",
};
/**
 * This logic is required because NextJS <Script/> onLoad and onReady
 * don't work:
 * @returns
 */
export const setScriptLoadedAction = (scriptName) => {
  return {
    type: "scriptLoaded",
    scriptName,
  };
};

export const showChromelessAction = (value = true) => {
  return {
    type: "chromeless",
    value,
  };
};

export const showGalleryModeAction = (value = true) => {
  return {
    type: "galleryMode",
    value,
  };
};

export const showPlayerOnlyAction = (value = true) => {
  return {
    type: "playerOnly",
    value,
  };
};

export const showLeftDrawerAction = (open) => {
  return {
    type: "showLeftDrawer",
    open,
  };
};

export const showRightDrawerAction = (open, sidebarComponent) => {
  return {
    type: "showRightDrawer",
    sidebarComponent,
    open,
  };
};

export const showMessageBarAction = (open, message) => {
  return {
    type: "messageBar",
    open,
    message:
      typeof message === "undefined"
        ? ""
        : message.replace(/([a-z])([A-Z])/g, "$1 $2"),
  };
};

export const showErrorMessageBarAction = (open, message, callback) => {
  return {
    type: "errorMessageBar",
    open,
    message:
      typeof message === "undefined"
        ? ""
        : message.replace(/([a-z])([A-Z])/g, "$1 $2"),
    callback,
  };
};

export const addToasterMessageAction = (notificationMessage) => {
  return {
    type: "addToasterMessage",
    notificationMessage,
  };
};

export const removeToasterMessageAction = (id) => {
  return {
    type: "removeToasterMessage",
    id,
  };
};

export const showModalAction = (modalName, open, modalTab) => {
  return {
    type: modalName,
    open,
    modalTab,
  };
};

export const showDialogAction = (dialogName, open, anchorEl, props) => {
  return {
    type: "showDialogAction",
    dialogName,
    open,
    anchorEl,
    props,
  };
};

export const showUIComponentAction = (uiComponent, open) => {
  return {
    type: "showUIComponent",
    uiComponent,
    open,
  };
};

export const isBlankPageAction = (isBlankPage) => {
  return {
    type: "isBlankPage",
    isBlankPage,
  };
};

export const incrementBrowserHistoryDispatcher = () => {
  return (dispatch, getState) => {
    let state = getState();
    if (
      !state.login.isLoggedIn &&
      state.ui.browserHistoryCount === 5 &&
      !state.ui.declinedToCreateLogin
    )
      dispatch(showModalAction(ModalName.CreateLoginModal, true, "register"));
    dispatch(incrementBrowserHistoryAction());
  };
};

export const incrementBrowserHistoryAction = () => {
  return {
    type: "incrementBrowserHistoryCount",
  };
};

export const showAccessRequestModalDispatcher = (
  entityTypeName,
  entityId,
  shareCodes
) => {
  return (dispatch, getState) => {
    var userSession = getState().current.entity.userSession;
    if (userSession) {
      const accessRequest = Object.assign(
        {
          entityTypeName: entityTypeName ? entityTypeName : EntityType.Account,
          entityId: entityId ? entityId : userSession.accountId,
        },
        shareCodes
      );
      dispatch(currentEntityAction(EntityType.AccessRequest, accessRequest));
      dispatch(showModalAction(ModalName.RequestAccessModal, true));
    }
  };
};

export const searchStringAction = (searchString, listName) => {
  return {
    type: "searchString",
    searchString,
    listName,
  };
};

export const resetUIStateAction = () => {
  return {
    type: "RESET_UI_STATE_ACTION",
  };
};

export const sortFieldAction = (entityTypeName, sortField) => {
  return {
    type: "sortField",
    entityTypeName,
    sortField,
  };
};

export const showCreateLoginModalDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(showModalAction(ModalName.CreateLoginModal, true, "login"));
  };
};

export const openNotificationMessageLinkDispatcher = (notificationMessage) => {
  return (dispatch, getState) => {
    dispatch(
      markParentNotificationMessagesReadDispatcher(notificationMessage.id)
    );
    dispatch(removeToasterMessageAction(notificationMessage.id));
    const entity = notificationMessage.entity;
    const entityTypeName = EntityType[notificationMessage.entityType];
    switch (entityTypeName) {
      case EntityType.ChannelSubscription:
      case EntityType.Channel:
        browserHistory.push(`/channel/${entity.channelId}`);
        break;
      case EntityType.ChatMessage:
        dispatch(openChatSidebarAction(entity.fromUser.id));
        break;
      case EntityType.Comment:
      case EntityType.Content:
        browserHistory.push(
          `/channel/${entity.channelId}/channelContent/${entity.contentId}`
        );
        browserHistory.push(
          isFreeAccount(getState().current.entity.account)
            ? `/profile/${entity.createdByUserId}/userUploads/${entity.contentId}`
            : `/channel/${entity.channelId}/channelContent/${entity.contentId}`
        );
        break;
      default:
        break;
    }
  };
};

export const openChatSidebarAction = (chatUserId) => {
  return (dispatch, getState) => {
    const { userSession } = getState().current.entity;
    dispatch(currentListAction("chatMessages", []));
    dispatch(showRightDrawerAction(true, RightSidebarComponent.ChatSidebar));
    dispatch(currentIdDispatcher(EntityType.ChatUser, chatUserId));
    const chatConversationId = generateChatConversationId(
      chatUserId,
      userSession.user.id
    );
    dispatch(currentIdAction(EntityType.ChatConversation, chatConversationId));
  };
};

export const setDeviceAPIAction = (deviceAPI) => {
  return {
    type: "setDeviceAPI",
    deviceAPI,
  };
};
