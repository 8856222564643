import {
  randomString,
  isWWW,
  browserHistory,
  initIntercom,
  getLocation,
  isTestEnv,
  shutdownIntercom,
  isPixelMixerAccount,
  isTestAgent,
  shareCodesFromQuery,
} from "../../util/Utils";
import { fetchEntityService } from "../../service/FetchEntityService";
import {
  socketConnectionDispatcher,
  socketDisconnectionDispatcher,
} from "./EntitySocketActions";
import {
  EntityType,
  currentEntityAction,
  currentIdAction,
  clearCurrentStateAction,
  fetchCurrentEntityAction,
  fetchCurrentListDispatcher,
} from "./CurrentActions";
import request from "superagent";
import { showErrorMessageBarAction, resetUIStateAction } from "./UIActions";
import { deleteUserSessionDispatcher } from "./DeleteEntityActions";
import {
  signinCognito,
  signoutCognito,
  getCognitoUser,
  configureAmplify,
  signinOauth,
  oauthHostname,
} from "../../service/AmplifyLoginService";
import {
  createUserSessionDispatcher,
  sendPasswordResetLinkDispatcher,
} from "./CreateEntityActions";

import { updateEntityService } from "../../service/UpdateEntityService";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { logDebug, logError } from "../../service/ServiceUtil";
import { syncExtensionStorageFromPixelMixer } from "../../service/ChromeExtensionService";
import { createEntityService } from "../../service/CreateEntityService";

export const AccountUserRole = {
  user: "USER",
  admin: "ADMIN",
};

export const getUserRoleName = (accountUser) => {
  if (accountUser && accountUser.accountUserRole) {
    if (accountUser.accountOwner) return "Owner";
    else if (accountUser.accountUserRole === AccountUserRole.admin)
      return "Account Admin";
    else return "Account User";
  } else return "User";
};
/**
 * Refreshes only the userSession object if the user's permissions or session related
 * needs to be refreshed from the backend.
 * @param {*} sessionKey
 */
export const refreshUserSessionDispatcher = (sessionKey) => {
  return async (dispatch, getState) => {
    if (sessionKey !== null) {
      const userSession = await dispatch(
        fetchCurrentEntityAction(EntityType.UserSession, sessionKey)
      );
      dispatch(currentIdAction(EntityType.UserSession, userSession.id));
      dispatch(currentEntityAction(EntityType.UserSession, userSession));
    }
  };
};

/**
 * Loads (or unloads) all current and session state related asundry from a userSession object
 * Called when auto-loading the userSession from a createUser, resetPassword or bootstrap process.
 * @param {*} userSession
 */
export const refreshCurrentUserSessionStateDispatcher = (userSession) => {
  return (dispatch, getState) => {
    //Always set sessionKey first:
    dispatch(updateSessionKeyAction(userSession));
    if (userSession !== null) {
      dispatch(currentIdAction(EntityType.UserSession, userSession.id));
      dispatch(currentEntityAction(EntityType.UserSession, userSession));
      dispatch(
        currentIdAction("ownerAccountUser", userSession.accountUser?.id)
      );
      dispatch(currentIdAction(EntityType.Account, userSession.accountId));
      dispatch(currentEntityAction(EntityType.Account, userSession.account));
      const ui = getState().ui;
      if (!isWWW) {
        dispatch(
          socketConnectionDispatcher(
            userSession.sessionKey,
            userSession.accountId
          )
        );
        dispatch(callConnectionStatusObserversAction());
        if (userSession.accountUser)
          dispatch(fetchCurrentListDispatcher("onlineEntitySocketSessions"));
        if (ui.isBlankPage) browserHistory.push("/");
        else if (getLocation().pathname?.startsWith("/login")) {
          browserHistory.push("/");
        }
        if (
          isAccountUpgradeRequired(
            userSession.account,
            userSession.accountUser
          ) &&
          window?.location.pathname !== "/subscription"
        ) {
          browserHistory.push("/upgradeAccount");
          dispatch(showErrorMessageBarAction(true, "Video Limit Exceeded"));
        }
        syncExtensionStorageFromPixelMixer();
      }
      const user = userSession.user;
      if (!isPixelMixerAccount) {
        const datadogUser = {
          id: user.id,
          name: user.fullName,
          email: user.emailAddress,
          plan: userSession.account?.accountType,
          accountId: userSession.account?.id,
          accountUserId: userSession.accountUser?.id,
          sessionKey: userSession.sessionKey,
        };
        datadogRum.setUser(datadogUser);
        datadogLogs.setUser(datadogUser);
      }
      if (!ui.chromeless && !ui.galleryMode) initIntercom(userSession);
    } else dispatch(currentEntityAction(EntityType.UserSession, null));
  };
};

export const loginDispatcher = (emailAddress, password, shareCodes) => {
  return async (dispatch, getState) => {
    try {
      const coginitoUser = await signinCognito(emailAddress, password);
      //Cognito requires password reset:
      if (coginitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(sendPasswordResetLinkDispatcher(emailAddress));
      } else {
        return await dispatch(createUserSessionDispatcher(shareCodes));
      }
    } catch (error) {
      //Core requires password reset (TODO: remove once most power users have migrated):
      if (error.errorCode === "PasswordResetRequiredException") {
        dispatch(sendPasswordResetLinkDispatcher(emailAddress));
        dispatch(
          showErrorMessageBarAction(
            true,
            "Please check email to reset password."
          )
        );
        throw error;
      } else {
        dispatch(showErrorMessageBarAction(true, error.userMessage));
        throw error;
      }
    }
  };
};

export const logoutDispatcher = () => {
  return async (dispatch, getState) => {
    const sessionKey = getState().login.sessionKey;
    if (sessionKey) {
      dispatch(deleteUserSessionDispatcher(sessionKey));
      dispatch(updateSessionKeyAction(null));
    }
    await signoutCognito();
    dispatch(clearCurrentStateAction());
    dispatch(resetUIStateAction());
    dispatch(socketDisconnectionDispatcher());
    shutdownIntercom();
    document.location = `https://${oauthHostname}/oauthSignOut`;
  };
};

export const setPasswordDispatcher = (formObject, shareCodes) => {
  return async (dispatch, getState) => {
    const user = await updateEntityService.updateEntity(
      dispatch,
      null,
      EntityType.User,
      null,
      formObject,
      "setPassword"
    );
    dispatch(showErrorMessageBarAction(false));
    const coginitoUser = await signinCognito(
      user.emailAddress,
      formObject.password
    );
    return await dispatch(createUserSessionDispatcher(shareCodes));
  };
};

export const resetPasswordDispatcher = (keyCode, password) => {
  return async (dispatch, getState) => {
    const formObject = { keyCode, password };
    const passwordReset = await updateEntityService.updateEntity(
      dispatch,
      null,
      EntityType.PasswordReset,
      null,
      formObject,
      "resetPassword"
    );
    dispatch(showErrorMessageBarAction(false));
    const coginitoUser = await signinCognito(
      passwordReset.emailAddress,
      password
    );
    dispatch(createUserSessionDispatcher({}));
  };
};

/**
 * Loads the userSession when the app starts if sessionKey is available:
 */
export const bootstrapUserSessionAndLoginStateDispatcher = () => {
  return async (dispatch, getState) => {
    // Check if user has an active Cognito session:
    const cognitoUser = await getCognitoUser();
    const sessionKey = getState().login.sessionKey;
    // if Cognito session is active check for sessionKey:
    if (cognitoUser) {
      // If sessionKey is present, fetch userSession
      if (sessionKey) {
        try {
          const userSession = await fetchEntityService.fetchEntity(
            dispatch,
            null,
            EntityType.UserSession,
            sessionKey,
            null,
            shareCodesFromQuery()
          );
          //If userSession is valid, refresh userSession state
          dispatch(refreshCurrentUserSessionStateDispatcher(userSession));
        } catch (error) {
          //If userSession is not valid, clear sessionKey and create a new userSession:
          dispatch(updateSessionKeyAction(null));
          await dispatch(createUserSessionDispatcher(shareCodesFromQuery()));
        }
      }
      //If sessionKey is not present, create a new userSession
      else {
        await dispatch(createUserSessionDispatcher(shareCodesFromQuery()));
      }
    }
    // if Cognito session is not active, clear sessionKey:
    else {
      dispatch(updateSessionKeyAction(null));
    }
    dispatch(setBootstrapComplete());
  };
};

export const checkIdentityProviderLoginDispatcher = () => {
  return async (dispatch, getState) => {
    const { sessionKey } = getState().login;
    if (!sessionKey) {
      try {
        const cognitoUser = await getCognitoUser();
        if (!cognitoUser) {
          //Don't pass dispatch/getState in order to avoid processServiceResponse:
          const identityProvider = await fetchEntityService.fetchEntity(
            null,
            null,
            EntityType.IdentityProvider
          );
          if (identityProvider.id === 0) return false;
          else {
            configureAmplify(true);
            await signinOauth(
              identityProvider.name,
              window?.location.origin,
              window?.location.pathname,
              window?.location.search,
              window?.location.hash
            );
            return true;
          }
        }
      } catch (e) {
        //If the identity provider was not found, redirect user to home page:
        if (window) window.location = `https://${oauthHostname}`;
      }
    }
    return false;
  };
};

export const updateSessionKeyAction = (userSession) => {
  return {
    type: "UPDATE_SESSION_KEY_ACTION",
    userSession,
  };
};

export const setBootstrapComplete = () => {
  return {
    type: "SET_BOOTSTRAP_COMPLETE",
  };
};

export const handleNetworkReconnectionDispatcher = () => {
  return async (dispatch, getState) => {
    logDebug("handleNetworkReconnectionDispatcher");
    const userSession = getState().current.entity.userSession;
    if (userSession?.accountUser)
      dispatch(fetchCurrentListDispatcher("onlineEntitySocketSessions"));
    dispatch(callConnectionStatusObserversAction());
  };
};

export const callConnectionStatusObserversAction = () => {
  return {
    type: "CALL_CONNECTION_STATUS_OBSERVERS_ACTION",
  };
};

export const addConnectionStatusObserverAction = (observer) => {
  return {
    type: "ADD_CONNECTION_STATUS_OBSERVER_ACTION",
    observerId: randomString(10),
    observer,
  };
};

export const removeConnectionStatusObserverAction = (observerId) => {
  return {
    type: "REMOVE_CONNECTION_STATUS_OBSERVER_ACTION",
    observerId,
  };
};

export const weVideoLoginDispatcher = (channelId, contentId) => {
  return (dispatch, getState) => {
    fetchEntityService
      .fetchEntity(dispatch, getState, EntityType.WeVideo, null, null, {
        channelId,
        contentId,
      })
      .then((weVideo) => {
        dispatch(currentIdAction(EntityType.WeVideo, weVideo.id));
        request
          .get(weVideo.ssoUrl)
          .withCredentials(true)
          .set("Accept", "application/json")
          .end((error, response) => {
            if (response && response.statusCode === 202) {
              if (weVideo.editorUrl)
                dispatch(currentEntityAction(EntityType.WeVideo, weVideo));
            } else if (error) {
              dispatch(showErrorMessageBarAction(true, error.message));
            }
          });
      })
      .catch((error) => {});
  };
};

export const isAllowedToShareContent = (
  accountUser,
  content,
  channel,
  account
) => {
  return (
    isContentAdmin(accountUser, content, channel, account) ||
    isChannelAdmin(accountUser, channel, account) ||
    isAccountAdmin(accountUser, account) ||
    (Boolean(accountUser) &&
      Boolean(account) &&
      account.allowSharing &&
      Boolean(content) &&
      content.allowSharing)
  );
};

export const isAllowedToShareChannel = (accountUser, channel, account) => {
  return (
    isChannelAdmin(accountUser, channel, account) ||
    isAccountAdmin(accountUser, account) ||
    (Boolean(accountUser) &&
      Boolean(account) &&
      account.allowSharing &&
      Boolean(channel) &&
      channel.allowSharing)
  );
};

export const isAllowedToCreateChannels = (accountUser, account) => {
  return (
    (Boolean(accountUser) && Boolean(account) && account.allowNewChannels) ||
    isAccountAdmin(accountUser, account)
  );
};

export const isAllowedToInviteOthers = (accountUser, account) => {
  return isAccountAdmin(accountUser, account) && Boolean(account);
};

export const isAccountOwner = (accountUser, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(account) &&
    accountUser.id === account.ownerAccountUserId
  );
};

export const isFreeAccount = (account) => {
  return account?.accountType === "FREE";
};

export const isAccountUpgradeRequired = (account, accountUser) => {
  return isAccountAdmin(accountUser, account) && account.upgradeRequired;
};

export const isAccountAdmin = (accountUser, account) => {
  if (accountUser && account)
    for (var i = 0; i < account.adminAccountUserIds.length; i++)
      if (account.adminAccountUserIds[i] === accountUser.id) return true;
  return false;
};

export const isUserOnline = (user, onlineEntitySocketSessions = []) => {
  if (user)
    for (var i = 0; i < onlineEntitySocketSessions.length; i++)
      if (onlineEntitySocketSessions[i].userId === user.id) return true;
  return false;
};

export const isChannelAdmin = (accountUser, channel, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(channel) &&
    (isChannelOwner(accountUser, channel) ||
      isAccountAdmin(accountUser, account))
  );
};

export const isChannelOwner = (accountUser, channel) => {
  return (
    Boolean(accountUser) &&
    Boolean(channel) &&
    accountUser.id === channel.ownerAccountUserId
  );
};

export const isContentAdmin = (accountUser, content, channel, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(content) &&
    (accountUser.id === content.createdByAccountUser.id ||
      accountUser.id === content.channelOwnerAccountUserId ||
      isAccountAdmin(accountUser, account) ||
      isChannelAdmin(accountUser, channel, account))
  );
};

export const isChannelSubscriber = (accountUser, channel) => {
  if (accountUser && channel) {
    for (var i = 0; i < accountUser.activeChannelSubscriptionIds.length; i++)
      if (accountUser.activeChannelSubscriptionIds[i] === channel.id)
        return true;
  }
  return false;
};

export const hasLikedContent = (user, content) => {
  if (user && content) {
    for (var i = 0; i < user.likedContentIds.length; i++)
      if (user.likedContentIds[i] === content.id) return true;
  }
  return false;
};

export const hasUnikedContent = (user, content) => {
  if (user && content) {
    for (var i = 0; i < user.unlikedContentIds.length; i++)
      if (user.unlikedContentIds[i] === content.id) return true;
  }
  return false;
};

export const isWatchLaterContent = (user, content) => {
  if (user && content) {
    for (var i = 0; i < user.watchLaterContentIds.length; i++)
      if (user.watchLaterContentIds[i] === content.id) return true;
  }
  return false;
};

export const transferCredentialsAndOpenWebappDispatcher = (
  origin,
  redirect
) => {
  return async (dispatch, getState) => {
    try {
      const credentialsObject = {};
      Object.keys(localStorage).forEach((key) => {
        if (key.indexOf("CognitoIdentityServiceProvider.") === 0) {
          credentialsObject[key] = localStorage.getItem(key);
          localStorage.removeItem(key);
        }
      });
      //Pass in null for state so it uses www and doesn't throw a CORS error:
      await createEntityService.createEntity(
        null,
        null,
        "identityProvider/oauthCredentials",
        {},
        { credentialsObject: JSON.stringify(credentialsObject) }
      );
      document.location =
        origin +
        "/login" +
        (redirect ? `?redirect=${encodeURIComponent(redirect)}` : "");
    } catch (e) {
      logError(
        "transferCredentialsAndOpenWebappDispatcher",
        { origin, redirect },
        e
      );
      throw e;
    }
  };
};
