import {
  processServiceResponse,
  getAuthHeaders,
  getBaseUrl,
} from "./ServiceUtil";
import request from "superagent";

export default class DeleteEntityService {
  deleteEntity(dispatch, getState, entityTypeName, entityId) {
    return new Promise(async (resolve, reject) => {
      let url = `${getBaseUrl(getState)}/api/v1/${entityTypeName}/${entityId}`;
      const authHeaders = await getAuthHeaders(getState);
      request
        .delete(url)
        .set(authHeaders)
        .set("Accept", "application/json")
        .type("application/x-www-form-urlencoded")
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
        });
    });
  }
}

export var deleteEntityService = new DeleteEntityService();
