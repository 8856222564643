import { useEffect, useRef, useState } from "react";
import {
  EXTENSION_OPEN_HASH,
  EXTENSION_SIGN_IN_PATH,
} from "../pixelmixer-webapp/src/service/ChromeExtensionService";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { useRouter } from "next/router";
import {
  logDebug,
  logError,
} from "../pixelmixer-webapp/src/service/ServiceUtil";
import {
  getQueryParams,
  isPixelMixerAccount,
  isTestAgent,
  isTestEnv,
} from "../pixelmixer-webapp/src/util/Utils";
/*global jQuery*/
/*global window*/

export const useScript = ({
  src,
  async = true,
  type = "text/javascript",
  textContent,
}) => {
  useEffect(() => {
    if (!src) return;
    const script = document.createElement("script");

    script.src = src;
    script.async = true;
    script.textContent = textContent;
    script.type = type;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

export const useAnalytics = () => {
  useEffect(() => {
    if (!isTestAgent()) {
      const script = document.createElement("script");
      script.src = "/static/js/analytics.js";
      document.body.appendChild(script);

      // return () => {
      //   document.body.removeChild(script);
      // };
    }
  }, []);
};

export const useTestimonials = (sliderEl, carouselEl) => {
  useEffect(() => {
    if (sliderEl && carouselEl) {
      $(sliderEl).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: ".foter-carousel",
        dots: false,
        arrows: true,
        nav: true,
        loop: true,
        centerMode: false,
        focusOnSelect: true,
        autoplay: true,
        autoplayTimeout: 500,
        speed: 3500,
        prevArrow: $(".testimonial-next-arrow"),
        nextArrow: $(".testimonial-prev-arrow"),
      });
      $(carouselEl).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        nav: true,
        prevArrow: $(".testimonial-next-arrow"),
        nextArrow: $(".testimonial-prev-arrow"),
        fade: true,
        loop: true,
        asNavFor: ".slider-nav1",
        speed: 3500,
      });
    }
    return () => {
      if (sliderEl) $(sliderEl).slick('unslick');
      if (carouselEl) $(carouselEl).slick('unslick');
    };
  }, [sliderEl, carouselEl]);
};

export const usePathname = () => {
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const { pathname, hash = "" } = isMounted ? window.location : {};
  return pathname;
};

export const useWebsiteRoutePathname = () => {
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const router = useRouter();
  const { pathname, hash = "" } = isMounted ? window.location : router;
  const queryParams = getQueryParams();
  const { redirect } = isMounted ? queryParams : router.query;

  const isSupportedPath = (path = "") =>
    path.startsWith(EXTENSION_SIGN_IN_PATH) ||
    path.startsWith("/slackUser") ||
    path.startsWith("/slackKeywordAlerts") ||
    path.startsWith("/slackChannel") ||
    path.startsWith("/myProfile/preferences") ||
    path.startsWith("/myProfile/keywords") ||
    path.startsWith("/myProfile/integrations") ||
    path.startsWith("/zoomBot") ||
    path.startsWith("/zoomLink");

  return hash.startsWith(EXTENSION_OPEN_HASH)
    ? hash
    : isSupportedPath(pathname)
    ? pathname
    : isSupportedPath(redirect)
    ? redirect
    : "/";
};

export const useSourceForgeReviews = () => {
  const setupTags = (variantId) => {
    const sc = document.createElement("script");
    sc.async = true;
    sc.src =
      "https://b.sf-syn.com/badge_js?sf_id=3561893&variant_id=" + variantId;
    const p = document.getElementsByTagName("script")[0];
    p.parentNode.insertBefore(sc, p);
  };
  useEffect(() => {
    setupTags("sf");
    setupTags("sd");
  }, []);
};

export const useStickyHeader = (headerEl, enableFloatingNavBar) => {
  useEffect(() => {
    if (headerEl) {
      if (enableFloatingNavBar) {
        $(window).on("scroll", (event) => {
          var scroll = $(window).scrollTop();
          if (scroll < 1) {
            $(headerEl).removeClass("sticky");
          } else {
            $(headerEl).addClass("sticky");
          }
        });
      } else {
        $(headerEl).addClass("sticky");
      }
    }
    return () => {
      if (headerEl) $(headerEl).removeClass("sticky");
    };
  }, [headerEl, enableFloatingNavBar]);
};

let isDataDogLoaded = false;
export const useDataDog = () => {
  useEffect(() => {
    try {
      if (!isPixelMixerAccount && !isTestAgent() && !isDataDogLoaded) {
        datadogRum.init({
          applicationId: "75e4a7ff-3c59-40cd-b077-1be9adf4c469",
          clientToken: "pub0ff4a629fbffc0a5998f1e1c6afadb6f",
          site: "us5.datadoghq.com",
          service: "pixelmixer-website",
          env: isTestEnv ? "dev" : "prod",
          // version: '1.0.0',
          sessionSampleRate: 100,
          premiumSampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackSessionAcrossSubdomains: true,
          defaultPrivacyLevel: "allow",
          allowedTracingUrls: [
            (url) => url.indexOf("pixelmixer.com/api/v1") > 0,
          ],
        });

        datadogRum.startSessionReplayRecording();

        datadogLogs.init({
          clientToken: "pub0ff4a629fbffc0a5998f1e1c6afadb6f",
          site: "us5.datadoghq.com",
          service: "pixelmixer-website",
          env: isTestEnv ? "dev" : "prod",
          // version: '1.0.0',
          forwardErrorsToLogs: true,
          sessionSampleRate: 100,
          trackSessionAcrossSubdomains: true,
        });

        datadogLogs.logger.setLevel(isTestEnv ? "debug" : "warn");
        console.log(
          "Set DataDog log level to: " + datadogLogs.logger.getLevel()
        );
        isDataDogLoaded = true;
      }
    } catch (e) {
      console.error("Unable to init DataDog", e);
    }
  }, []);
};
