import { showMessageBarAction, showErrorMessageBarAction } from "./UIActions";
import {
  EntityType,
  updateCurrentListItemDispatcher,
  Operation,
  currentEntityAction,
} from "./CurrentActions";
import { deleteEntityService } from "../../service/DeleteEntityService";
import { refreshUserSessionDispatcher } from "./LoginActions";

export const deleteCurrentEntityDispatcher = (
  entityType,
  entityId,
  callback
) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, entityType, entityId)
      .then((entity) => {
        let currentIdState = getState().current.id;
        let parentEntityIdName = entityType + "Id";
        // Only delete currentList of currentEntityId is still the same as the parentId
        if (
          typeof currentIdState[parentEntityIdName] !== "undefined" &&
          currentIdState[parentEntityIdName] === entityId
        ) {
          dispatch(currentEntityAction(entityType, null));
        }
        dispatch(
          showMessageBarAction(
            true,
            `${entityType.replace(/([A-Z])/g, " $1").trim()} deleted`
          )
        );
        callback && callback(entity);
      })
      .catch((e) => {});
  };
};

export const deleteUserSessionDispatcher = (sessionKey) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, EntityType.UserSession, sessionKey)
      .then((userSession) => {})
      .catch((e) => {});
  };
};

export const deleteAccountInviteDispatcher = (accountInviteId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(
        dispatch,
        getState,
        EntityType.AccountInvite,
        accountInviteId
      )
      .then((accountInvite) => {
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.AccountInvite,
            accountInvite,
            Operation.Delete
          )
        );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Invite cancelled`));
      })
      .catch((e) => {});
  };
};

export const deleteChannelDispatcher = (channelId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, EntityType.Channel, channelId)
      .then((channel) => {
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.Channel,
            channel,
            Operation.Delete
          )
        );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `${channel.name} deleted`));
      })
      .catch((e) => {});
  };
};

export const deleteChannelShareDispatcher = (channelShareId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, EntityType.ChannelShare, channelShareId)
      .then((channelShare) => {
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.ChannelShare,
            channelShare,
            Operation.Delete
          )
        );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Share deleted`));
      })
      .catch((e) => {});
  };
};

export const deleteContentShareDispatcher = (contentShareId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, EntityType.ContentShare, contentShareId)
      .then((contentShare) => {
        dispatch(
          updateCurrentListItemDispatcher(
            EntityType.ContentShare,
            contentShare,
            Operation.Delete
          )
        );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Share deleted`));
      })
      .catch((e) => {});
  };
};

export const deleteContentDispatcher = (contentId) => {
  return async (dispatch, getState) => {
    const content = await deleteEntityService.deleteEntity(
      dispatch,
      getState,
      EntityType.Content,
      contentId
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Content,
        content,
        Operation.Delete
      )
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showMessageBarAction(true, `${content.name} deleted`));
  };
};

export const deleteCommentDispatcher = (commentId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(dispatch, getState, EntityType.Comment, commentId)
      .then((comment) => {
        //Delete parent comments in place:
        if (comment.id === commentId)
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.Comment,
              comment,
              Operation.Delete
            )
          );
        //Delete a child comment by updating parent:
        else
          dispatch(
            updateCurrentListItemDispatcher(
              EntityType.Comment,
              comment,
              Operation.Update
            )
          );
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Comment deleted`));
      })
      .catch((e) => {});
  };
};

export const deleteChannelSubscriptionDispatcher = (channelId) => {
  return (dispatch, getState) => {
    deleteEntityService
      .deleteEntity(
        dispatch,
        getState,
        EntityType.ChannelSubscription,
        channelId
      )
      .then((channelSubscription) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(
          showErrorMessageBarAction(
            true,
            `You have unsubscribed from ${channelSubscription.channel.name}`
          )
        );
        dispatch(refreshUserSessionDispatcher(getState().login.sessionKey));
      })
      .catch((e) => {});
  };
};
