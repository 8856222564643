import {
  showErrorMessageBarAction,
  showModalAction,
  ModalName,
} from "../redux/action/UIActions";
import {
  isAccountAdmin,
  logoutDispatcher,
  updateSessionKeyAction,
} from "../redux/action/LoginActions";
import {
  currentEntityAction,
  EntityType,
} from "../redux/action/CurrentActions";
import {
  getAuthorizationHeaderValue,
  getIdentityHeaderValue,
  redirectToGoogleSignIn,
} from "./AmplifyLoginService";
import { datadogLogs } from "@datadog/browser-logs";
import { isSSR } from "../util/Utils";
import { createLogger, format, transports, config } from "winston";

const ssrLogger = createLogger({
  format: format.json(),
  level: "info",
  transports: [new transports.Console()],
});

export const getBaseUrl = (getState) => {
  const baseUrl = getState && getState() && getState().login.baseUrl;
  return !!baseUrl ? baseUrl : "";
};

export const getAuthHeaders = async (getState) => {
  //Don't set null values because NextJs will send as strings:
  const autHeaders = {};
  const authorization = await getAuthorizationHeaderValue();
  if (authorization) autHeaders["Authorization"] = authorization;
  if (getState && getState().login.sessionKey)
    autHeaders["x-session-key"] = getState().login.sessionKey;
  return autHeaders;
};

export const getIdentityHeader = async (getState) => {
  const idHeader = await getIdentityHeaderValue();
  return {
    Identity: idHeader,
  };
};

export const processServiceResponse = (
  dispatch,
  getState,
  error,
  response,
  resolve,
  reject
) => {
  // This method is also hotwired by SSR getContentAndChannel:
  const userSession = getState && getState().current?.entity?.userSession;
  if (typeof response === "undefined") {
    dispatch &&
      dispatch(
        showErrorMessageBarAction(true, "Not connected to the Internet")
      );
    reject({
      errorCode: "NetworkError",
      errorMessage: error,
      userMessage: "Not connected to the Internet",
    });
  } else {
    if (response.ok) return resolve(response.body);
    else {
      if (!response.statusCode || response.notFound) {
        dispatch &&
          dispatch(
            showErrorMessageBarAction(true, "Server error. Contact support")
          );
        reject({ errorCode: "ServerError", errorMessage: response.statusText });
      } else if (response.statusCode === 502) {
        dispatch &&
          dispatch(
            showErrorMessageBarAction(true, "Server offline. Contact support")
          );
        reject({ errorCode: "ServerError", errorMessage: response.statusText });
      } else {
        if (
          typeof response.body !== "undefined" &&
          response.body &&
          typeof response.body.errorCode !== "undefined"
        ) {
          if (
            response.statusCode === 401 &&
            response.body.errorCode === "NotSignedIn"
          ) {
            dispatch && dispatch(updateSessionKeyAction(null));
            window.router.push("/login");
          } else if (
            response.statusCode === 401 &&
            response.body.errorCode === "LoginRequired"
          ) {
            dispatch && dispatch(updateSessionKeyAction(null));
            window.router.push("/createLogin");
          } else if (
            response.statusCode === 403 &&
            response.body.errorCode === "GoogleSignInRequired"
          ) {
            dispatch && dispatch(updateSessionKeyAction(null));
            redirectToGoogleSignIn();
          } else if (
            //TBD: Implement SessionExpired Logic server side:
            response.statusCode === 401 &&
            response.body.errorCode === "SessionExpired"
          ) {
            dispatch && dispatch(logoutDispatcher());
            window.router.push("/login");
          } else if (
            response.statusCode === 410 &&
            response.body.errorCode === "ShareExpired"
          ) {
            window.router.push("/login");
          } else if (
            response.statusCode === 417 &&
            response.body.errorCode === "EmailVerificationRequired"
          ) {
            dispatch &&
              dispatch(
                currentEntityAction(EntityType.User, {
                  emailAddress: response.body.emailAddress,
                })
              );
            dispatch &&
              dispatch(showModalAction(ModalName.VerifyEmailPinModal, true));
          } else if (
            response.statusCode === 417 &&
            response.body.errorCode === "PhoneVerificationRequired"
          ) {
            window.router.push("/");
            dispatch &&
              dispatch(currentEntityAction(EntityType.User, response.body));
            dispatch &&
              dispatch(showModalAction(ModalName.VerifyMobileModal, true));
          } else if (
            response.statusCode === 403 &&
            response.body.errorCode === "AccountSignInRequired"
          ) {
            //Give some time for the user to see the error message:
            setTimeout(() => {
              if (window) window.location = response.body.redirectUrl;
            }, 3000);
          } else if (
            response.statusCode === 417 &&
            response.body.errorCode === "PhoneRequired"
          ) {
            dispatch &&
              dispatch(showModalAction(ModalName.EditMyProfileModal, true));
          }
          dispatch &&
            dispatch(
              showErrorMessageBarAction(true, response.body.userMessage)
            );
          logWarning("processServiceResponse", response.body, error);
          reject(response.body);
        } else if (
          response.statusCode === 410 &&
          response.body.errorCode === "UpgradeRequired"
        ) {
          dispatch &&
            dispatch(
              showErrorMessageBarAction(true, response.body.userMessage)
            );
          if (
            userSession &&
            isAccountAdmin(userSession.accountUser, userSession.account)
          )
            window.router.push("/upgradeAccount");
        } else {
          dispatch &&
            dispatch(showErrorMessageBarAction(true, response.statusText));
          reject({
            errorCode: "ServerError",
            errorMessage: response.statusText,
          });
          logError("processServiceResponse", response.body, error);
        }
      }
    }
  }
};

export const logDebug = (message, messageContext = {}) => {
  if (!isSSR) {
    console.log("logDebug: " + message, messageContext);
    datadogLogs.logger.debug(message, messageContext);
  } else ssrLogger && ssrLogger.debug(message, messageContext);
};

export const logInfo = (message, messageContext = {}) => {
  if (!isSSR) {
    console.log("logInfo: " + message, messageContext);
    datadogLogs.logger.info(message, messageContext);
  } else ssrLogger && ssrLogger.info(message, messageContext);
};

export const logWarning = (message, messageContext = {}, error) => {
  if (!isSSR) {
    console.warn("logWarning: " + message, messageContext, error);
    datadogLogs.logger.warn(message, messageContext, error);
  } else ssrLogger && ssrLogger.warn(message, messageContext);
};

export const logError = (message, messageContext = {}, error) => {
  if (!isSSR) {
    console.log("logError: " + message, messageContext, error);
    datadogLogs.logger.error(message, messageContext, error);
  } else ssrLogger && ssrLogger.error(message, messageContext);
};
