import { showAccessRequestModalDispatcher } from "../redux/action/UIActions";
import request from "superagent";
import {
  processServiceResponse,
  logDebug,
  getAuthHeaders,
  getBaseUrl,
} from "./ServiceUtil";

export default class FetchListService {
  fetchList(
    dispatch,
    getState,
    listName,
    parentEntityTypeName,
    parentEntityId,
    queryObject = {}
  ) {
    var useMock = false;
    return new Promise(async (resolve, reject) => {
      //Don't put account in the URL because that will be determined from the hostname:
      let fetchListUrl = `${getBaseUrl(getState)}/api/v1`;
      if (parentEntityTypeName && parentEntityId) {
        fetchListUrl = `${fetchListUrl}/${parentEntityTypeName}/${parentEntityId}/${listName}`;
      } else if (parentEntityTypeName) {
        fetchListUrl = `${fetchListUrl}/${parentEntityTypeName}/${listName}`;
      } else {
        fetchListUrl = `${fetchListUrl}/${listName}`;
      }
      const authHeaders = await getAuthHeaders(getState);
      logDebug("fetchListUrl", { fetchListUrl });
      request
        .get(fetchListUrl)
        .set(authHeaders)
        .set("Accept", "application/json")
        .query(queryObject)
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
          if (
            response &&
            response.statusCode === 403 &&
            response.body &&
            response.body.errorCode === "NoAccess"
          ) {
            dispatch &&
              dispatch(
                showAccessRequestModalDispatcher(
                  parentEntityTypeName,
                  parentEntityId,
                  queryObject
                )
              );
          }
        });
    });
  }
}

export var fetchListService = new FetchListService();
